body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.fixed-height {
  height: 80px; /* Adjust the value as per your preference */
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Google Sign-In Button */
.btn-google {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: #4285F4; /* Google Blue */
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-google:hover {
  background-color: #357AE8; /* Darker shade on hover */
}

.btn-google:disabled, .btn-google.disabled {
  background-color: #D3D3D3; /* Disabled color */
  cursor: not-allowed;
}

.btn-google svg {
  margin-right: 8px;
}
